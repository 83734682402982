const participants =
`杨幂	13110000000
李易峰	13110000001
李宇春	13110000002
张靓颖	13110000003
周笔畅	13110000004
韩雪	13110000005
范冰冰	13110000006
赵薇	13110000007
马苏	13110000008
李小璐	13110000009
刘涛	13110000010
林依晨	13110000011
陈乔恩	13110000012
容祖儿	13110000013
林心如	13110000014
`;

export default participants;
